/* padding-left */
.pl-0 {
    padding-left: 0px !important;
}

.pl-1  {
    padding-left: 1px !important;
}

.pl-2  {
    padding-left: 2px !important;
}

.pl-3  {
    padding-left: 3px !important;
}

.pl-4  {
    padding-left: 4px !important;
}

.pl-5  {
    padding-left: 5px !important;
}

.pl-6  {
    padding-left: 6px !important;
}

.pl-7  {
    padding-left: 7px !important;
}

.pl-8  {
    padding-left: 8px !important;
}

.pl-9  {
    padding-left: 9px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-11  {
    padding-left: 11px !important;
}

.pl-12  {
    padding-left: 12px !important;
}

.pl-13  {
    padding-left: 13px !important;
}

.pl-14  {
    padding-left: 14px !important;
}

.pl-15  {
    padding-left: 15px !important;
}

.pl-16  {
    padding-left: 16px !important;
}

.pl-17  {
    padding-left: 17px !important;
}

.pl-18  {
    padding-left: 18px !important;
}

.pl-19  {
    padding-left: 19px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-21  {
    padding-left: 21px !important;
}

.pl-22  {
    padding-left: 22px !important;
}

.pl-23  {
    padding-left: 23px !important;
}

.pl-24  {
    padding-left: 24px !important;
}

.pl-25  {
    padding-left: 25px !important;
}

.pl-26  {
    padding-left: 26px !important;
}

.pl-27  {
    padding-left: 27px !important;
}

.pl-28  {
    padding-left: 28px !important;
}

.pl-29  {
    padding-left: 29px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-31  {
    padding-left: 31px !important;
}

.pl-32  {
    padding-left: 32px !important;
}

.pl-33  {
    padding-left: 33px !important;
}

.pl-34  {
    padding-left: 34px !important;
}

.pl-35  {
    padding-left: 35px !important;
}

.pl-36  {
    padding-left: 36px !important;
}

.pl-37  {
    padding-left: 37px !important;
}

.pl-38  {
    padding-left: 38px !important;
}

.pl-39  {
    padding-left: 39px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pl-41  {
    padding-left: 41px !important;
}

.pl-42  {
    padding-left: 42px !important;
}

.pl-43  {
    padding-left: 43px !important;
}

.pl-44  {
    padding-left: 44px !important;
}

.pl-45  {
    padding-left: 45px !important;
}

.pl-46  {
    padding-left: 46px !important;
}

.pl-47  {
    padding-left: 47px !important;
}

.pl-48  {
    padding-left: 48px !important;
}

.pl-49  {
    padding-left: 49px !important;
}

.pl-50 {
    padding-left: 50px !important;
}

.pl-51  {
    padding-left: 51px !important;
}

.pl-52  {
    padding-left: 52px !important;
}

.pl-53  {
    padding-left: 53px !important;
}

.pl-54  {
    padding-left: 54px !important;
}

.pl-55  {
    padding-left: 55px !important;
}

.pl-56  {
    padding-left: 56px !important;
}

.pl-57  {
    padding-left: 57px !important;
}

.pl-58  {
    padding-left: 58px !important;
}

.pl-59  {
    padding-left: 59px !important;
}

.pl-60 {
    padding-left: 60px !important;
}

.pl-61  {
    padding-left: 61px !important;
}

.pl-62  {
    padding-left: 62px !important;
}

.pl-63  {
    padding-left: 63px !important;
}

.pl-64  {
    padding-left: 64px !important;
}

.pl-65  {
    padding-left: 65px !important;
}

.pl-66  {
    padding-left: 66px !important;
}

.pl-67  {
    padding-left: 67px !important;
}

.pl-68  {
    padding-left: 68px !important;
}

.pl-69  {
    padding-left: 69px !important;
}

.pl-70 {
    padding-left: 70px !important;
}

.pl-71  {
    padding-left: 71px !important;
}

.pl-72  {
    padding-left: 72px !important;
}

.pl-73  {
    padding-left: 73px !important;
}

.pl-74  {
    padding-left: 74px !important;
}

.pl-75  {
    padding-left: 75px !important;
}

.pl-76  {
    padding-left: 76px !important;
}

.pl-77  {
    padding-left: 77px !important;
}

.pl-78  {
    padding-left: 78px !important;
}

.pl-79  {
    padding-left: 79px !important;
}
.pl-80  {
    padding-left: 80px !important;
}
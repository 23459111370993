.buttonWrapper {
  border-radius: 6px;
  display: flex !important;
  align-items: center;
  font-weight: 500;
  line-height: 10px;
  justify-content: center;
  height: 37px !important;

  .name {
    font-weight: 700;
    line-height: 14px;
  }

  .icon {
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .iconLarge svg {
    height: 18px;
    width: 18px;
  }
}

.disable {
  box-shadow: none !important;
  cursor: not-allowed;

  &:not(.icon svg path) {
    opacity: 0.7;
  }

  .icon svg path {
    &[fill] {
      fill: #ced4da;
    }

    &[stroke] {
      stroke: #ced4da;
    }
  }
}

@mixin btnType(
  $backgroundColor,
  $borderColor,
  $color,
  $hoverBackground,
  $hoverBorderColor,
  $hoverColor
) {
  background: $backgroundColor !important;
  border-color: $borderColor !important;
  color: $color !important;

  // &:hover,
  // &:focus {
  //   opacity: 0.8;
  //   background: $hoverBackground !important;
  //   border-color: $hoverBorderColor !important;
  //   color: $hoverColor !important;
  //   span {
  //     color: $hoverColor !important;
  //   }
  // }
  span {
    color: $color !important;
  }
}
// KHÔNG XÓA
// .custom {
// #154398 :màu xanh chủ đạo
//   &:hover {
//
//   }
//   &:focus {
//
//   }
//   &:active {
//
//   }
// div span {
// }

// svg path {

// }
// }
.dynamic1 {
  position: relative;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #ff7f50;
  transition: all 0.2s ease;

  &:hover:before,
  &:hover::after {
    animation: orangeTopBubbles 0.6s ease;
  }

  &:focus {
    /* Add your focus styles here */
  }

  &:active {
    transform: scale(0.95);
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    width: 150%;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    z-index: -1000;
    background-repeat: no-repeat;
  }

  &:hover:before {
    top: -70%;
    background-image: radial-gradient(circle, #ff7f50 20%, transparent 20%),
      radial-gradient(circle, transparent 20%, #ff7f50 20%, transparent 30%),
      radial-gradient(circle, #ff7f50 20%, transparent 20%),
      radial-gradient(circle, #ff7f50 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #ff7f50 15%, transparent 20%),
      radial-gradient(circle, #ff7f50 20%, transparent 20%),
      radial-gradient(circle, #ff7f50 20%, transparent 20%),
      radial-gradient(circle, #ff7f50 20%, transparent 20%),
      radial-gradient(circle, #ff7f50 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%,
      15% 15%, 10% 10%, 18% 18%;
    background-position: 50% 120%;
  }

  &:hover::after {
    bottom: -70%;
    background-image: radial-gradient(circle, #ff7f50 20%, transparent 20%),
      radial-gradient(circle, #ff7f50 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #ff7f50 15%, transparent 20%),
      radial-gradient(circle, #ff7f50 20%, transparent 20%),
      radial-gradient(circle, #ff7f50 20%, transparent 20%),
      radial-gradient(circle, #ff7f50 20%, transparent 20%),
      radial-gradient(circle, #ff7f50 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%,
      18% 18%;
    background-position: 50% 0%;
  }

  @keyframes orangeTopBubbles {
    0% {
      background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
        40% 90%, 55% 90%, 70% 90%;
    }

    50% {
      background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
        50% 50%, 65% 20%, 90% 30%;
    }

    100% {
      background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
        50% 40%, 65% 10%, 90% 20%;
      background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
  }

  @keyframes orangeBottomBubbles {
    0% {
      background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
        70% -10%, 70% 0%;
    }

    50% {
      background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
        105% 0%;
    }

    100% {
      background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
        110% 10%;
      background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
  }
}

.dynamic {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // gap: 10px;
  // font-family: inherit;
  // font-size: 16px;
  // font-weight: 600;
  // text-transform: uppercase;
  // letter-spacing: 0.8px;
  color: #fff;
  background: #154398;
  border: 2px solid #154398;
  // border-style: none;
  border-radius: 8px;
  // padding: 16px 24px;
  transform: scale(1);
  transition: 0.1s;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  // #154398 :màu xanh chủ đạo
  &:hover {
    border-color: #154398 !important;
    background: #fff !important;
    color: #154398 !important;

    div span {
      color: #154398 !important;
    }

    svg path {
      fill: #154398;
      stroke: #154398;
    }
  }
  &:focus {
  }
  &:active {
    transform: scale(0.9);
    box-shadow: none;
  }
}
.SteveBloX {
  --button_radius: 0.75em;
  --button_color: #e8e8e8;
  --button_outline_color: #000000;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --highlight-color: rgba(255, 255, 255, 0.2);
  font-size: 17px;
  font-weight: bold;
  border: none;
  border-radius: var(--button_radius);
  background: linear-gradient(
    to bottom right,
    var(--highlight-color),
    var(--shadow-color)
  );
  box-shadow: 6px 6px 16px var(--shadow-color),
    -6px -6px 16px var(--highlight-color);
  cursor: pointer;
  &:hover {
    transform: translateY(-0.33em);
  }
  &:focus {
  }
  &:active {
    transform: translateY(0);
  }
  div span {
    display: block;
    box-sizing: border-box;
    border: 2px solid var(--button_outline_color);
    border-radius: var(--button_radius);
    padding: 0.75em 1.5em;
    background: var(--button_color);
    color: var(--button_outline_color);
    transform: translateY(-0.2em);
    transition: transform 0.1s ease;
  }
}

.linear {
  @include btnType(
    linear-gradient(90deg, #154398 0%, #ed1117 100%),
    #ffffff,
    #ffffff,
    linear-gradient(90deg, #154398 0%, #ed1117 100%),
    #ffffff,
    #ffffff
  );
}
.greenLeaves {
  @include btnType(#7fca27, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff);
  &:hover {
    background: #9acf1c !important;
    color: #ffffff !important;

    div span {
      // color: #154398 !important;
    }

    svg path {
      // fill: #154398;
      // stroke: #154398;
    }
  }
}

.primary {
  @include btnType(#154398, #154398, #ffffff, #154398, #154398, #ffffff);

  &:hover {
    background: #fff !important;
    color: #154398 !important;

    div span {
      color: #154398 !important;
    }

    svg path {
      fill: #154398;
      stroke: #154398;
    }
  }
}
.custom {
  align-items: center;
  appearance: none;
  background-color: #eef2ff;
  border-radius: 8px;
  border-width: 2px;
  border-color: #154398;
  box-shadow: rgba(83, 109, 254, 0.2) 0 2px 4px,
    rgba(83, 109, 254, 0.15) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #154398;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 56px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  // will-change: box-shadow, transform;
  font-size: 20px;

  &:hover {
    box-shadow: rgba(83, 109, 254, 0.3) 0 4px 8px,
      rgba(83, 109, 254, 0.2) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    transform: translateY(-2px);
    color: #154398 !important;
    border-color: #154398 !important;
  }
  &:focus {
    outline: none;
    box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(83, 109, 254, 0.4) 0 2px 4px,
      rgba(83, 109, 254, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  }
  &:active {
    box-shadow: #d6d6e7 0 3px 7px inset;
    transform: translateY(2px);
  }
}

.yellow {
  @include btnType(#ffd800, #ffd800, #172b4d, #ffd800, #ffd800, #172b4d);
}

.secondary {
  @include btnType(#b6bcc4, #b6bcc4, #ffffff, #b6bcc4, #b6bcc4, #ffffff);
  font-weight: 700 !important;
}

.closeVND {
  @include btnType(#777779, #777779, #ffffff, #777779, #777779, #ffffff);
}

.green {
  @include btnType(#ffffff, #0d99ff, #0d99ff, #ffffff, #0d99ff, #0d99ff);
}

.third {
  @include btnType(#ffffff, #154398, #154398, #0e8cf1, #0e8cf1, #ffffff);

  &:hover
  // ,
  // &:focus
  {
    background: #154398 !important;
    color: #fff !important;
    // .ant-btn-loading-icon span svg {
    //   fill: #fff;
    // }

    div span {
      color: #fff !important;
    }

    svg path {
      fill: #154398;
      stroke: #fff;
    }
  }
}

.third-st {
  @include btnType(#ffffff, #154398, #154398, #0e8cf1, #0e8cf1, #ffffff);

  &:hover
  // ,
  // &:focus
  {
    svg path {
      stroke: white;
    }
  }
}

.fourth {
  @include btnType(#2c2c35, #c4c4c4, #c4c4c4, #2c2c35, #c4c4c4, #c4c4c4);
}

.fifth {
  @include btnType(#ffffff, #8b8a92, #8b8a92, #ffffff, #8b8a92, #8b8a92);
}

.orange {
  @include btnType(#f7941e, #f7941e, #ffffff, #f7941e, #f7941e, #ffffff);
}

.back {
  @include btnType(#f0f0f0, #505050, #505050, #f0f0f0, #505050, #505050);
}

.gray {
  @include btnType(#f2f2f2, #f2f2f2, #f2f2f2, #f2f2f2, #f2f2f2, #f2f2f2);

  .icon svg {
    height: 24px;
    width: 24px;
  }

  height: 40px !important;
}

.primaryFilter {
  @include btnType(#0d99ff, #0d99ff, #ffffff, #0d99ff, #0d99ff, #ffffff);

  .icon svg {
    height: 24px;
    width: 24px;
  }

  height: 40px !important;
  width: 40px !important;
}

.dark {
  @include btnType(#757575, #757575, #ffffff, #757575, #757575, #ffffff);
}

.primary-sm {
  @include btnType(#d3f7ff, #d3f7ff, #0d99ff, #d3f7ff, #d3f7ff, #0d99ff);
}

.danger {
  @include btnType(#ffffff, #e50500, #e50500, #e50500, #e50500, #ffffff);

  &:hover {
    background: #e50500 !important;
    color: #fff !important;

    div span {
      color: #fff !important;
    }

    svg path {
      fill: #154398;
      stroke: #fff;
    }

    svg path {
      fill: white;
    }
  }
}

.red {
  @include btnType(#e50500, #ffffff, #ffffff, #ffffff, #ffffff, #e50500);

  &:hover {
    svg path {
      fill: e50500;
    }
  }
}

.svg-24 {
  .icon svg {
    height: 24px;
    width: 24px;
  }
}

.svg-18 {
  border-radius: 50% !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 32px !important;
  width: 32px !important;
  min-width: 32px !important;
  padding-left: 4px;

  .icon svg {
    height: 18px;
    width: 18px;
  }

  &:hover {
    background: #cff4e1 !important;
  }

  &.zoomIn svg {
    animation-name: zoomIn;
    animation-duration: 0.5s;
  }
}

.btn-circle {
  border-radius: 50% !important;
  border: none !important;
  background-color: white;
  box-shadow: none !important;
  height: 30px !important;
  width: 30px !important;
  box-shadow: 0px 2px 5px rgba(187, 199, 208, 0.5);
  min-width: 20px !important;

  .icon svg {
    height: 20px;
    width: 20px;
  }

  // &:hover {
  //   background: #cff4e1 !important;
  // }
  &.zoomIn svg {
    animation-name: zoomIn;
    animation-duration: 0.5s;
  }
}

.minWidth {
  min-width: 84px;
}

@keyframes zoomIn {
  from {
    width: 0px;
    height: 0px;
  }
}

.tex-transform-lowercase {
    text-transform: lowercase;
}
.text-uppercase{
    text-transform: uppercase;
}

.link-underline{
    color: #0d99ff;
    text-decoration: underline;
  }
  
  .file-node{
    background: #e0dfdf;
    border-radius: 12px;
    padding: 4px 10px;
    margin-right: 8px;
  }
/* padding-right */
.pr-0 {
    padding-right: 0px !important;
}

.pr-1  {
    padding-right: 1px !important;
}

.pr-2  {
    padding-right: 2px !important;
}

.pr-3  {
    padding-right: 3px !important;
}

.pr-4  {
    padding-right: 4px !important;
}

.pr-5  {
    padding-right: 5px !important;
}

.pr-6  {
    padding-right: 6px !important;
}

.pr-7  {
    padding-right: 7px !important;
}

.pr-8  {
    padding-right: 8px !important;
}

.pr-9  {
    padding-right: 9px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-11  {
    padding-right: 11px !important;
}

.pr-12  {
    padding-right: 12px !important;
}

.pr-13  {
    padding-right: 13px !important;
}

.pr-14  {
    padding-right: 14px !important;
}

.pr-15  {
    padding-right: 15px !important;
}

.pr-16  {
    padding-right: 16px !important;
}

.pr-17  {
    padding-right: 17px !important;
}

.pr-18  {
    padding-right: 18px !important;
}

.pr-19  {
    padding-right: 19px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-21  {
    padding-right: 21px !important;
}

.pr-22  {
    padding-right: 22px !important;
}

.pr-23  {
    padding-right: 23px !important;
}

.pr-24  {
    padding-right: 24px !important;
}

.pr-25  {
    padding-right: 25px !important;
}

.pr-26  {
    padding-right: 26px !important;
}

.pr-27  {
    padding-right: 27px !important;
}

.pr-28  {
    padding-right: 28px !important;
}

.pr-29  {
    padding-right: 29px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-31  {
    padding-right: 31px !important;
}

.pr-32  {
    padding-right: 32px !important;
}

.pr-33  {
    padding-right: 33px !important;
}

.pr-34  {
    padding-right: 34px !important;
}

.pr-35  {
    padding-right: 35px !important;
}

.pr-36  {
    padding-right: 36px !important;
}

.pr-37  {
    padding-right: 37px !important;
}

.pr-38  {
    padding-right: 38px !important;
}

.pr-39  {
    padding-right: 39px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.pr-41  {
    padding-right: 41px !important;
}

.pr-42  {
    padding-right: 42px !important;
}

.pr-43  {
    padding-right: 43px !important;
}

.pr-44  {
    padding-right: 44px !important;
}

.pr-45  {
    padding-right: 45px !important;
}

.pr-46  {
    padding-right: 46px !important;
}

.pr-47  {
    padding-right: 47px !important;
}

.pr-48  {
    padding-right: 48px !important;
}

.pr-49  {
    padding-right: 49px !important;
}

.pr-50 {
    padding-right: 50px !important;
}

.pr-51  {
    padding-right: 51px !important;
}

.pr-52  {
    padding-right: 52px !important;
}

.pr-53  {
    padding-right: 53px !important;
}

.pr-54  {
    padding-right: 54px !important;
}

.pr-55  {
    padding-right: 55px !important;
}

.pr-56  {
    padding-right: 56px !important;
}

.pr-57  {
    padding-right: 57px !important;
}

.pr-58  {
    padding-right: 58px !important;
}

.pr-59  {
    padding-right: 59px !important;
}

.pr-60 {
    padding-right: 60px !important;
}

.pr-61  {
    padding-right: 61px !important;
}

.pr-62  {
    padding-right: 62px !important;
}

.pr-63  {
    padding-right: 63px !important;
}

.pr-64  {
    padding-right: 64px !important;
}

.pr-65  {
    padding-right: 65px !important;
}

.pr-66  {
    padding-right: 66px !important;
}

.pr-67  {
    padding-right: 67px !important;
}

.pr-68  {
    padding-right: 68px !important;
}

.pr-69  {
    padding-right: 69px !important;
}

.pr-70 {
    padding-right: 70px !important;
}

.pr-71  {
    padding-right: 71px !important;
}

.pr-72  {
    padding-right: 72px !important;
}

.pr-73  {
    padding-right: 73px !important;
}

.pr-74  {
    padding-right: 74px !important;
}

.pr-75  {
    padding-right: 75px !important;
}

.pr-76  {
    padding-right: 76px !important;
}

.pr-77  {
    padding-right: 77px !important;
}

.pr-78  {
    padding-right: 78px !important;
}

.pr-79  {
    padding-right: 79px !important;
}
.pr-80  {
    padding-right: 80px !important;
}